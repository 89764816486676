var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"zQ1GA-obGDMGaotCiq4F7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (['prod', 'stage'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    dsn: 'https://0cc190c9b5354066bfd359cf2147dc65@o4505584619945984.ingest.sentry.io/4505584627679232',
    // Replay may only be enabled for the client-side
    integrations: [new Sentry.Replay()],
    hideSourceMaps: process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // ...

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
